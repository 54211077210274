import {
  ContentWrapper,
  IconsItem,
  IconsItemWrapper,
  IconsWrapper,
  StyleSwiperSlide,
  Title,
  Wrapper,
} from "./Skills.styles";
import RevealEffect from "components/reveal-effect/RevealEffect";
import { skillsIcons } from "./skillsIcons";
import SwiperComponent from "components/swiper-component/SwiperComponent";
import useViewport from "hooks/useViewport/useViewport";
import { useMemo, useState } from "react";
// import useHideContentHash from "hooks/useHideContentHash";

export const Skills: React.FC = () => {
  const { extraSmall, small, medium } = useViewport();
  // const hideContent = useHideContentHash("#skills");
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const slidesPerView = extraSmall ? 2 : small ? 4 : medium ? 5 : 7;

  const skillIconsData = useMemo(() => {
    return skillsIcons.map((skillIcon, index) => (
      <StyleSwiperSlide key={`${skillIcon}-${index}`}>
        <RevealEffect
          effect="fade"
          direction="down"
          duration={1000}
          delay={
            (extraSmall
            ? index <= 2
            : small
            ? index <= 4
            : medium
            ? index <= 5
            : index <= 7)
              ? 200 * index
              : 50 * index
          }
        >
          <IconsItemWrapper className="shadow-md">
            <IconsItem>
              <img src={skillIcon.icon} alt={skillIcon.name} />
              <p className="bold">{skillIcon.name}</p>
            </IconsItem>
          </IconsItemWrapper>
        </RevealEffect>
      </StyleSwiperSlide>
    ));
  }, [extraSmall, medium, small]);

  // if (hideContent) {
  //   return <></>;
  // }

  return (
    <Wrapper>
      <RevealEffect effect="fade" direction="up">
        <div>
          <Title>Skills</Title>
          <h2>List of my Tech Stack</h2>
        </div>
      </RevealEffect>
      <ContentWrapper>
        <RevealEffect delay={1500} duration={1000} direction="down">
          <IconsWrapper>
            <SwiperComponent
              autoplay={{
                delay: isFirstSlide ? 5000 : 2000,
                disableOnInteraction: true,
              }}
              grabCursor
              navigation={!extraSmall}
              slidesPerView={slidesPerView}
              onSlideChange={(slide) =>
                isFirstSlide &&
                slide.activeIndex !== 0 &&
                setIsFirstSlide(false)
              }
              onDestroy={() => !isFirstSlide && setIsFirstSlide(true)}
            >
              {skillIconsData}
            </SwiperComponent>
          </IconsWrapper>
        </RevealEffect>
      </ContentWrapper>
    </Wrapper>
  );
};
