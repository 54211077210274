import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media } from "utils/custom-media";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${media.lteSmallMedia} {
    overflow-x: hidden;
    padding: 70px 5%;
  }

  ${media.extraSmallMedia} {
    padding-top: 20px;
  }

  @media (min-width: 100em) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;

  ${media.lteSmallMedia} {
    flex-direction: column;
  }

  // <=1600px
  @media (min-width: 64em) and (max-width: 100em) {
    gap: 20px;
  }
`;

export const LeftWrapper = styled.div`
  ${media.gteMediumMedia} {
    flex: 0 0 50%;
    text-align: start;
    display: flex;
    justify-content: center;
    max-width: 50%;
  }
`;

export const RightWrapper = styled.div`
  ${media.gteMediumMedia} {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 20px;

  ${media.gteMediumMedia} {
    max-width: 550px;
  }
`;

export const AboutMeTitleWrapper = styled.div`
  text-align: start;
`;

export const AboutMeTitle = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-regular);
`;

export const Description = styled.p`
  text-align: start;
  line-height: 1.8;
  text-wrap: pretty;

  ${media.extraSmallMedia} {
    font-size: 15px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: 520px;
  width: 100%;
  user-select: none;
  margin-top: 85px;
  margin-bottom: 20px;

  ${media.lteSmallMedia} {
    height: auto;
    margin-top: 0;
  }

  ${media.gteSmallMedia} {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  width: 460px;
  height: auto;
  border-radius: 20px;

  ${media.lteSmallMedia} {
    width: 100%;
  }

  ${media.mediumMedia} {
    width: 400px;
  }
`;

export const SlideWrapper = styled.div`
  margin-top: 1rem;

  ${media.lteSmallMedia} {
    max-width: 90vw;
  }
`;
interface SlideItemWrapperProps {
  isFirst?: boolean;
  isLast?: boolean;
}

export const SlideItemWrapper = styled.div<SlideItemWrapperProps>`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 6px;
  padding: 0 25px 0;
  min-height: 157px;

  ${({ isFirst, isLast }) =>
    isFirst
      ? css`
          margin-left: 0;
        `
      : isLast &&
        css`
          margin-right: 0;
        `}

  ${media.extraSmallMedia} {
    // padding: 0 35px 0;

    ${({ isFirst }) =>
      isFirst
        ? css`
            // padding: 0 50px 0;
          `
        : css`
            // padding: 0 35px 0;
          `}
  }

  ${media.smallMedia} {
    ${({ isFirst }) =>
      isFirst
        ? css`
            // padding: 0 55px 0;
          `
        : css`
            // padding: 0 42px 0;
          `}
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  margin-bottom: 5px;
  opacity: 0.75;
`;

export const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: nowrap;

  p {
    color: white;
    margin-top: 5px;
    // font-size: 14px;
    // letter-spacing: 0.8px;
    white-space: normal;
    text-align: center;
  }
`;

export const SlideDetails = styled.span`
  margin: 5px 0;
  font-size: 26px;
`;
