import ReactFullpage from "@fullpage/react-fullpage";

import {
  LoaderItem,
  ParallaxBackground,
  PreLoader,
  PreLoaderBounce,
} from "./Homepage.styles";

import Header from "components/header/Header";
import { headerLinks } from "utils/header-links";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPageScroller, { SectionContainer } from "react-page-scroller";
import React from "react";

export const Homepage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState<{
    hash: string;
    index: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const sectionRefs = useRef<Array<HTMLElement | null>>([]);
  const notScrollableSections = ["skills", "projects"];

  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(false);
    };

    // let showPage;

    setTimeout(() => {
      // showPage = true;
      onPageLoad();
    }, 2000);

    // if (document.readyState === "complete" && showPage === true) {
    //   onPageLoad();
    // } else {
    //   window.addEventListener("load", onPageLoad, false);
    //   return () => window.removeEventListener("load", onPageLoad);
    // }
  }, []);

  const preLoader = (
    <>
      <PreLoader isLoaded={!isLoading}>
        <PreLoaderBounce>
          <LoaderItem />
          <LoaderItem />
          <LoaderItem />
        </PreLoaderBounce>
      </PreLoader>
    </>
  );

  // Function to handle scrolling to section when page loads with hash
  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash.replace("#", "");

      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    scrollToHash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Detect current section
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = headerLinks.findIndex(
              (headerLink) =>
                headerLink.href.replace("#", "") === entry.target.id
            );
            setActiveSection({ hash: entry.target.id, index });
          }
        });
      },
      { threshold: 0.8 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const preventScroll = (event: WheelEvent) => {
      const target = event.target as HTMLElement;
      const { scrollTop, scrollHeight, clientHeight } = target;

      if (
        target.scrollTop === 0 &&
        event.deltaY < 0 &&
        activeSection?.index === 0
      ) {
        event.preventDefault();
      }

      if (
        scrollTop + clientHeight >= scrollHeight &&
        event.deltaY > 0 &&
        activeSection?.index === headerLinks.length - 1
      ) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", preventScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", preventScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    if (
      activeSection?.hash &&
      activeSection?.hash !== location.hash.replace("#", "")
    ) {
      navigate(`#${activeSection?.hash}`);
    }
  }, [activeSection, location.hash, navigate]);

  return (
    <>
      {!isLoading && <Header />}
      {preLoader}
      {headerLinks.map((headerLink, index) => {
        const isNoScrollable = notScrollableSections.includes(
          headerLink.href.replace("#", "")
        );
        return (
          <section
            className="section-snap"
            key={`homepage-${headerLink.name}`}
            id={headerLink.href.replace("#", "")}
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <ParallaxBackground index={index + 1}>
              <div className={!isNoScrollable ? "slide-scrollable" : undefined}>
                {headerLink.component}
              </div>
            </ParallaxBackground>
          </section>
        );
      })}
    </>
  );
};
