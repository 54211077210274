import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import { media } from "utils/custom-media";

type StyledProps = {
  index?: number;
  isLoaded?: boolean;
};

export const HeaderWrapper = styled.header`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #fff;
  line-height: 20px;
  margin-top: 10px;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 700;
`;

export const SmallName = styled.small`
  font-size: 80%;
  font-weight: 400;
`;

const gradient = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;

const move = keyframes`
from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
`;

const bounce = keyframes`
from {
  transform: translateY(0);
  }
  to {
    transform: translateY(-1.2em);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow: auto;
  position: relative;
`;

export const Section = styled.section`
  scroll-snap-align: start;
  height: 100vh;
`;

export const PreLoader = styled("div", {
  shouldForwardProp: (prop: string): boolean => prop !== "isLoaded",
})<StyledProps>`
  background: -webkit-linear-gradient(top, #16222a 0%, #3a6073 100%);
  // background: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999999;
  text-align: center;

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      display: none;
    `}
`;

export const PreLoaderBounce = styled.div`
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
`;

export const LoaderItem = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 7px;
  display: inline-block;
  vertical-align: middle;
  animation: ${bounce} 0.6s infinite alternate;
  background: #fff;

  &:nth-of-type(2) {
    animation-delay: 0.2s;
    background: #fff;
  }

  &:nth-of-type(3) {
    animation-delay: 0.3s;
    background: #fff;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ParallaxBackground = styled("div", {
  shouldForwardProp: (prop: string): boolean => prop !== "index",
})<StyledProps>`
  background-size: cover;
  position: relative;
  z-index: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-y: auto;

${media.gteSmallMedia} {
  transition: background-image 0.5s ease-in-out;
  background-attachment: fixed; 
}

  &:after {
    content: "";
    opacity: 0.5;
    // background: linear-gradient(-45deg, #16222a, #3a6073, #141e30, #243b55);
    background-color: #243b55;
    // transition: background-color 2s ease-out;
    background-size: 400% 400%;
    // animation: ${gradient} 15s ease infinite;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  ${({ index }) =>
    index === 1
      ? css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/home.webp");
          background-position: center 80%;

          ${media.lteSmallMedia} {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              ),
              url("images/background/home_mobile.webp");
          }
        `
      : index === 2
      ? css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/about.webp");
          background-position: center 80%;
        `
      : index === 3
      ? css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/skills.webp");
          background-position: 50% 65%;
        `
      : index === 4
      ? css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/timeline.webp");
          background-position: center 60%;
        `
      : index === 5
      ? css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/projects.webp");
          background-position: center 60%;
        `
      : index === 6 &&
        css`
          background-image: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            ),
            url("images/background/contact.webp");
          background-position: center 60%;
        `}
`;

export const Background = styled.div`
  font: 5vmin/1.3 Serif;
  overflow: hidden;
  background: #123;
  width: 100vw;
  height: 100vh;
  position: fixed;

  &:after {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 3em;
    height: 3em;
    content: " ";
    mix-blend-mode: screen;
    animation: 44s -27s ${move} infinite ease-in-out alternate;

    text-shadow: 1.9477901168em -0.0292283383em 7px rgba(0, 38, 255, 0.9),
      0.4787076259em 1.2263639191em 7px rgba(255, 0, 246, 0.9),
      2.3372734967em 0.1931486575em 7px rgba(0, 255, 201, 0.9),
      0.6179912351em 0.5633708656em 7px rgba(0, 255, 112, 0.9),
      1.3539037733em 0.1437629773em 7px rgba(0, 255, 89, 0.9),
      2.3454427576em 1.3813808719em 7px rgba(175, 255, 0, 0.9),
      0.3547525486em -0.243868596em 7px rgba(255, 57, 0, 0.9),
      1.2488373054em -0.3790096319em 7px rgba(122, 0, 255, 0.9),
      0.0337966431em -0.3782224903em 7px rgba(38, 0, 255, 0.9),
      0.1726152357em 2.2677376499em 7px rgba(0, 255, 183, 0.9),
      1.7375882136em -0.1484107406em 7px rgba(0, 255, 4, 0.9),
      -0.3850586682em 0.1683093531em 7px rgba(255, 0, 85, 0.9),
      2.4642455116em -0.1259222962em 7px rgba(0, 255, 216, 0.9),
      0.4883418792em 0.2285931376em 7px rgba(255, 0, 243, 0.9),
      0.8943083133em -0.4791350509em 7px rgba(255, 0, 43, 0.9),
      0.5709758881em 0.4723604713em 7px rgba(89, 0, 255, 0.9),
      1.8890264491em -0.1072189829em 7px rgba(83, 255, 0, 0.9),
      1.1181856128em 0.9468342225em 7px rgba(0, 210, 255, 0.9),
      0.6785238726em 2.0413931251em 7px rgba(255, 0, 218, 0.9),
      -0.3243614244em 0.4380419927em 7px rgba(255, 0, 40, 0.9),
      0.4082957128em -0.4727778267em 7px rgba(255, 0, 71, 0.9),
      0.1647925881em 1.4938917403em 7px rgba(255, 0, 160, 0.9),
      0.8924746128em 2.374757791em 7px rgba(255, 0, 168, 0.9),
      0.9682692605em 1.7686793941em 7px rgba(0, 255, 190, 0.9),
      1.4080516412em 1.3885353081em 7px rgba(255, 0, 41, 0.9),
      0.2834511178em 0.9647946754em 7px rgba(183, 255, 0, 0.9),
      0.3713728195em -0.3302936141em 7px rgba(26, 255, 0, 0.9),
      1.7927213719em 0.4496123569em 7px rgba(0, 183, 255, 0.9),
      0.4823242036em -0.229381996em 7px rgba(0, 255, 80, 0.9),
      1.2596084674em -0.2501504843em 7px rgba(255, 0, 61, 0.9),
      1.3241855436em -0.3483931828em 7px rgba(0, 255, 156, 0.9),
      0.4318363091em 1.4244751401em 7px rgba(0, 187, 255, 0.9),
      0.0299673114em 2.4441357964em 7px rgba(0, 59, 255, 0.9),
      -0.3804109145em -0.1280717701em 7px rgba(239, 255, 0, 0.9),
      1.4127514014em -0.0493489541em 7px rgba(60, 0, 255, 0.9),
      0.5383599246em 0.3488178165em 7px rgba(229, 255, 0, 0.9),
      1.8774369998em 0.5311858759em 7px rgba(81, 0, 255, 0.9),
      -0.0336876697em -0.3583480437em 7px rgba(255, 173, 0, 0.9),
      0.7268608124em 0.98522462em 7px rgba(0, 255, 113, 0.9),
      1.377713578em -0.4245999557em 7px rgba(58, 255, 0, 0.9),
      1.8497826533em 1.3475342225em 7px rgba(49, 255, 0, 0.9);
    animation-duration: 43s;
    animation-delay: -32s;
  }

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 3em;
    height: 3em;
    content: ".";
    mix-blend-mode: screen;
    animation: 44s -27s ${move} infinite ease-in-out alternate;

    text-shadow: 0.5519999346em 0.64635163em 7px rgba(68, 0, 255, 0.9),
      1.1626643005em 0.3699371025em 7px rgba(0, 255, 85, 0.9),
      0.1611012469em -0.3803372432em 7px rgba(255, 0, 197, 0.9),
      1.6680636726em 0.8910322423em 7px rgba(0, 255, 25, 0.9),
      1.7357122554em -0.0021448845em 7px rgba(0, 255, 66, 0.9),
      -0.0402587358em 1.1026011444em 7px rgba(0, 255, 85, 0.9),
      1.4407671601em -0.3956349583em 7px rgba(0, 44, 255, 0.9),
      0.1979381668em 2.0368469666em 7px rgba(0, 255, 199, 0.9),
      1.6990616619em 0.7935516731em 7px rgba(0, 20, 255, 0.9),
      1.4516233575em -0.3610025504em 7px rgba(122, 255, 0, 0.9),
      -0.3735358989em 0.0257706885em 7px rgba(0, 255, 30, 0.9),
      -0.1691039502em 0.1880804851em 7px rgba(148, 0, 255, 0.9),
      0.9209951767em 1.9652223854em 7px rgba(0, 108, 255, 0.9),
      1.3019000855em 0.020432297em 7px rgba(0, 196, 255, 0.9),
      0.8320124728em 0.3547730528em 7px rgba(255, 0, 102, 0.9),
      1.3721050832em 0.344483217em 7px rgba(3, 255, 0, 0.9),
      0.1947343902em 1.471225779em 7px rgba(0, 255, 82, 0.9),
      -0.3621298954em 0.3678852812em 7px rgba(152, 255, 0, 0.9),
      1.8148889678em -0.1328735782em 7px rgba(255, 0, 57, 0.9),
      1.4764705619em 0.1889169422em 7px rgba(255, 116, 0, 0.9),
      0.260240106em 1.9232187086em 7px rgba(189, 0, 255, 0.9),
      -0.1817698034em 0.0197123251em 7px rgba(126, 0, 255, 0.9),
      0.6517830888em -0.0009642959em 7px rgba(0, 255, 28, 0.9),
      1.4031684962em 1.7161967533em 7px rgba(0, 66, 255, 0.9),
      2.4236084912em 1.0760677614em 7px rgba(168, 255, 0, 0.9),
      -0.1880620785em 0.6201779194em 7px rgba(255, 0, 81, 0.9),
      2.3318545916em 2.0849940699em 7px rgba(130, 0, 255, 0.9),
      1.5918024832em 2.3218020826em 7px rgba(253, 0, 255, 0.9),
      0.5937173858em 1.860318275em 7px rgba(255, 0, 51, 0.9),
      1.6540371904em -0.494588643em 7px rgba(0, 253, 255, 0.9),
      2.3658296307em -0.3205128375em 7px rgba(239, 255, 0, 0.9),
      1.1793330945em 0.6611478642em 7px rgba(255, 231, 0, 0.9),
      2.202613701em 2.4441715112em 7px rgba(255, 143, 0, 0.9),
      0.8740590572em -0.21056337em 7px rgba(77, 255, 0, 0.9),
      2.0224174956em 1.2124749612em 7px rgba(0, 49, 255, 0.9),
      2.370991385em 1.5594658765em 7px rgba(0, 190, 255, 0.9),
      2.4410539414em 1.6726503802em 7px rgba(255, 73, 0, 0.9),
      0.4681061167em 0.4095782311em 7px rgba(252, 0, 255, 0.9),
      0.1222162271em 1.3017029058em 7px rgba(0, 255, 253, 0.9),
      0.3678125164em -0.3853966347em 7px rgba(87, 0, 255, 0.9),
      -0.0659572176em 0.8664806755em 7px rgba(255, 0, 90, 0.9);
    animation-duration: 44s;
    animation-delay: -27s;
  }
`;

export const SocialIconsContainer = styled.div`
  padding: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 199;
`;

export const SocialIconsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const SocialIcon = styled.li`
  display: block;
  margin: 1px 0px;
`;

export const SocialIconLink = styled.a`
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  transition: all 0.4s ease;

  &:hover {
    color: #1e1e1e;
    background-color: #fff;

    svg{
      color: #1e1e1e;
    }
  }

  ${media.lteSmallMedia} {
    width:25px;
    height:30px;
    display:inline-block;
    line-height:30px;
    text-align:center;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255,255,255,1);
    font-size:14px;
  }
}
`;
