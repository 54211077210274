import styled from "@emotion/styled";
import { SwiperSlide } from "swiper/react";
import { media } from "utils/custom-media";

export const Wrapper = styled.div`
  padding: 0 5% 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.gteMediumMedia} {
    padding: 0 10% 0;
  }
`;

export const ContentWrapper = styled.div`
  // flex: 0 0 70%;
  // max-width: 70%;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-regular);
`;

export const IconsWrapper = styled.div`
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;

  // max-width: 1150px;
  justify-content: center;
  // user-select: none;
  // pointer-events: none;
`;

export const IconsItemWrapper = styled.div`
  background-color: rgba(250, 250, 250, 0.25);
  border-radius: 17px;
  margin: 6px;
  padding: 0 25px 0;
`;

export const StyleSwiperSlide = styled(SwiperSlide)`
  min-height: 200px;
`;

export const IconsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: nowrap;

  img {
    width: 80px;
    height: 80px;
  }

  p {
    color: white;
    margin-top: 8px;
    font-size: 14px;
    letter-spacing: 0.8px;
  }
`;
