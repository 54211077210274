import {
  ContactBox,
  ContactInput,
  ContactTextarea,
  ContentWrapper,
  LeftWrapper,
  RightWrapper,
  SubmitButton,
  Title,
  Wrapper,
} from "./Contact.styles";
import RevealEffect from "components/reveal-effect/RevealEffect";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Contact: React.FC = () => {
  return (
    <Wrapper>
      <RevealEffect effect="fade" direction="up">
        <div>
          <Title>Contact</Title>
          <h2>Get in Touch!</h2>
        </div>
      </RevealEffect>
      <ContentWrapper>
        <LeftWrapper>
          <RevealEffect delay={1500} duration={1000} direction="down">
            <div>
              <ContactBox className="bold">
                <FontAwesomeIcon icon={faLocationDot} />
                Porto, Portugal
              </ContactBox>
              <ContactBox className="bold">
                <FontAwesomeIcon icon={faEnvelope} />
                contact@luccasdev.com
              </ContactBox>
            </div>
          </RevealEffect>
        </LeftWrapper>
        <RightWrapper>
          <RevealEffect delay={1500} duration={1000} direction="down">
            <div>
              <ContactInput type="text" required placeholder="Name" />
              <ContactInput type="email" required placeholder="Email" />
              <ContactTextarea required placeholder="Message" />
              <SubmitButton>Submit</SubmitButton>
            </div>
          </RevealEffect>
        </RightWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
